@font-face {
    font-family: "heros_italic";
    src: url("../src/TeX-Gyre-Heros-fontfacekit/HelveticaNeue-BoldItalic.woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "heros_bold_italic";
    src: url("../src/TeX-Gyre-Heros-fontfacekit/HelveticaNeue-BoldItalic.woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "heros_bold";
    src: url("../src/TeX-Gyre-Heros-fontfacekit/HelveticaNeue-Bold.woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "heros";
    src: url("../src/TeX-Gyre-Heros-fontfacekit/fonts/texgyreheroscn_regular_macroman/texgyreheroscn-regular-webfont.woff");
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0);
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

html {
    background-color: white;
    overflow-x: hidden;
    box-sizing: border-box;
    width: 100vw;
    max-height: 100vh;
}

.App {
    font-family: "heros_bold";
    text-align: center;
    width: 100vw;
    max-height: 100vh;
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 45px 45px auto;
    grid-gap: 0px;
}

#ticker-a {
    width: 100vw;
    height: 50px;
}

#ticker-a * {
    overflow-y: hidden;
}

.ticker {
    background-color: black;
    width: 100vw;
    color: white;
    font-family: "heros_bold_italic";
    overflow: hidden;
    height: 45px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.ticker span {
    /* padding-top: 50px; */
    padding: 10px;
    margin-top: 20px;
    /* height: 50px; */
}

.menu {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    /* border-bottom: black solid 1px; */
    z-index: 1;
    background-color: white;
    width: 100vw;
    height: 40px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
}

.menu-item {
    align-self: center;
    border-right: 1px solid black;
    height: 45px;
}

.menu a {
    color: black;
    text-decoration: none;
    font-family: "heros_bold";
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.main-container {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
    display: grid;
    grid-gap: 0px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100vw;
    /* height: 90vh; */
    /* background-color: yellow; */
    border-top: 1px solid black;
}

.main {
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* overflow-y: scroll; */
}

.react-reveal {
    overflow-x: hidden;
}

.about-container {
    overflow-y: scroll;
}

.about {
    overflow-x: hidden;
    /* overflow-y: scroll; */
    cursor: default;
    background-color: #f2f2f2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    overflow: hidden;
    /* overflow-y: scroll; */
    /* align-items: stretch; */
    border-right: black solid 1px;
}

.page {
    grid-column-start: 1;
    grid-column-end: 5;
    overflow-x: hidden;
    /* height: inherit; */
}

.about-text {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 30px;
}

.page p {
    font-size: x-large;
    font-weight: bold;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    cursor: default;
}

.credits {
    text-align: end;
    bottom: 10px;
    /* display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start; */
    overflow: hidden;
    width: 100%;
}

.credits p {
    width: 90%;
    text-align: left;
    padding-left: 15px;
    padding-right: 10px;
    font-family: "heros_bold";
    margin-right: 0px;
}

p span {
    position: relative;
    /* color: red; */
    text-decoration: underline;
    font-weight: 800;
    cursor: default;
}

span {
    text-decoration: none;
}

p span img {
    position: absolute;
    display: none;
    visibility: hidden;
    max-width: 600px;
    max-height: 500px;
    z-index: 100;
    /* transition: all 0.8s; */
}

p a {
    text-decoration: underline;
    color: black;
}

/* p span:hover img {
    display: block;
    z-index: 1;
} */

/* .about {
    overflow-y: scroll;
} */

/* .about-p {
    overflow-y: scroll;
} */

.content p {
    width: 90%;
    font-size: 0.9em;
    text-align: left;
    padding-left: 15px;
    padding-right: 10px;
    font-family: "heros_bold";
}

.discussion {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 1em;
}

.discussion p {
    padding-left: 15px;
}

.discussion a {
    color: black;
    font-size: 0.9em;
    margin-top: 1em;
    padding-left: 15px;
    text-align: left;
}

.discussion-page {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
}

.discussion-page div {
    text-align: left;
}

.discussion-page div a {
    color: black;
    font-size: 0.9em;
    margin-bottom: 5px;
    text-align: left;
}

.discussion-page p {
    color: black;
    font-size: 1.2em;
    padding-left: 0px;
}

.disc-img {
    position: fixed;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -40%);
    min-width: 30vw;
    max-width: 48vw;
    max-height: 65vh;
    visibility: hidden;
}

.links-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 48vw;
    overflow-y: scroll;
    padding-bottom: 15px;
}

.links-main a {
    text-align: left;
}

#surv-div {
    background-image: url("../src/images/surv/image_4.jpg");
    background-position: 39%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.8s;
    overflow: hidden;
    height: 100%;
}

#surv-div:hover {
    background-image: url("../src/images/map_animation.gif");
    transition: all 0.8s;
}

.slide-img {
    /* width: 70vw; */
    max-width: 70vw;
    height: auto;
    object-fit: cover;
}

.alice-carousel__dots {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
    /* width: 20px; */
    background-color: transparent;
    cursor: pointer;
}

.alice-carousel__prev-btn {
    position: fixed;
    left: 1.5vw;
    top: 50%;
    transform: translate(0, -50%);
    text-align: left;
}

.alice-carousel__next-btn {
    position: fixed;
    text-align: right;
    right: 1.5vw;
    top: 50%;
    transform: translate(0, -50%);
}

.surveillance {
    /* display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center; */
    background-color: black;
    /* padding: 10px; */
}

/* .surveillance img {
    width: 40vw;
    margin: 20px;
    transition: all 0.8s;
    max-height: 50vh;
} */

/* .surveillance img:hover {
    width: 50vw;
    margin: 20px;
    transition: all 0.8s;
    max-height: 50vh;
} */

#urban-div {
    background-image: url("../src/images/urban_dict1.jpg");
    background-position: 36%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.8s;
    overflow: hidden;
    height: 100%;
}

#urban-div:hover {
    background-image: url("../src/images/imaginaries_optimise.gif");
    transition: all 0.8s;
}

/* .surv-img {
    object-fit: cover;
    background-image: url("../src/images/surv_atlas_1.png");
    background-position: 36%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.8s;
} */

/* .surv-img:hover {
    background-image: url("../src/images/batman.gif");
    transition: all 0.8s;
} */

/* .urban-img {
    width: 25vw;
    height: 93vh;
    object-fit: cover;
    background-image: url("../src/images/urban_dict1.jpg");
    background-position: 36%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.8s;
} */

/* .urban-img:hover {
    background-image: url("../src/images/batman.gif");
    transition: all 0.8s;
} */

.urban {
    background-image: url("../src/images/urban/urban08.png");
    background-position: 0 70%;
    width: 100vw;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
}

.footer a {
    color: black;
    text-decoration: none;
}

.box {
    position: fixed;
    /* cursor: grab; */
    /* left: 35%; */
    /* top: 25%; */
    /* transform: translate(-50%, -50%); */
    /* top: 300px; */
    /* height: 100px; */
    /* width: 35vw; */
    /* -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 1); */

    /* background-color: black; */
}

/* #box1 {
    background-image: url("./images/urban/urban01.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 30vw;
    height: 70vh;
    height: auto;
} */
/* .box:hover {
    transform: rotate(30deg);
} */

.box img {
    width: 35vw;
    height: auto;
    transition: all 0.8s;
    /* height: auto; */
}

/* .box img:hover {
    transform: rotate(2deg);
    transition: all 0.8s;
} */

.test {
    background-image: url("./images/surv_atlas_1.png");
    background-size: cover;
}

/* .image1 {
    background-image: url("./images/surv_atlas_1.png");
    background-size: cover;
} */
.drag {
    height: 200px;
    width: 200px;
}

@media screen and (max-width: 1300px) {
    p span img {
        max-width: 450px;
        max-height: 300px;
    }
}
